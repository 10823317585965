import React, { Component } from 'react';
import Navbar from './navbar/navbar'
import Top from './top/top'
import Why from './why/why'
import Goals from './goals/goals'
import Team from './team/team'
import Language from "../../language/languages"

const userLang = new Language((navigator.language || navigator.userLanguage).split("-")[0])

class App extends Component {
    state = { }
    render() { 
        document.title = "StudyView - " + userLang.get("page.title.home")
        return (
            <>
            <Navbar></Navbar>
            <Top section="top"></Top>
            <Why section="why"></Why>
            <Goals section="goals"></Goals>
            <Team section="team"></Team>
            </>
        );
    }
}
 
export default App;