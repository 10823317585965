import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import home from './components/home/home'
import register from './components/register/register'

document.title = 'StudyView'

function Home() {
  return (
    <main>
      <Switch>
        <Route path='/' component={home} exact />
        <Route path='/register' component={register} />
      </Switch>
    </main>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Home />
  </BrowserRouter>,
  document.getElementById('root')
)
