import React, { Component } from 'react'
import Language from '../../../language/languages'

const userLang = new Language(
    (navigator.language || navigator.userLanguage).split('-')[0]
)

export default class Why extends Component {
    render() {
        return (
            <section id={this.props.section}>
                <h1>{userLang.get('home.section.why.title')}</h1>
            </section>
        )
    }
}
