import React from 'react'
import { Link } from 'react-router-dom'

class BackButton extends React.Component {
    state = {  }
    render() { 
        return ( 
            <Link to="/" ><i className="fas fa-arrow-left back-button"></i></Link>
        )
    }
}
 
export default BackButton;