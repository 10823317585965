import React, { Component } from 'react'
import NavbarItem from './navbarItem'
import Language from "../../../language/languages"

const userLang = new Language((navigator.language || navigator.userLanguage).split("-")[0])

export default class Navbar extends Component {
    render() {
        return ( 
            <nav className="navbar navbar-dark fixed-top navbar-expand-md navbar-no-bg">
                <div className="container">
                    <a className="navbar-brand" href="/">StudyView</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <NavbarItem name={userLang.get("home.navbar.home")} href="#top"></NavbarItem>
                            <NavbarItem name={userLang.get("home.navbar.why")} href="#why"></NavbarItem>
                            <NavbarItem name={userLang.get("home.navbar.goal")} href="#goals"></NavbarItem>
                            <NavbarItem name={userLang.get("home.navbar.team")} href="#team"></NavbarItem>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}