import React, { Component } from 'react'

import Language from "../../language/languages"
import BackButton from "./backbutton/backbutton"

const userLang = new Language((navigator.language || navigator.userLanguage).split("-")[0])


export default class Register extends Component {

    render() {
        document.title = "StudyView - " + userLang.get("page.title.register")

        return (
            <>
                <BackButton />
            </>
        )
    }
}