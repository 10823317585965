import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Language from "../../../language/languages"

const userLang = new Language((navigator.language || navigator.userLanguage).split("-")[0])

export default class Top extends Component {
    render() {
        return ( 
            <section id={this.props.section}>
                <div className="top-content">
                    <div className="container">
                        <div className="row center">
                            <div className="text">
                                <h1 className="wow fadeInLeftBig">StudyView</h1>
                                <div className="description wow fadeInLeftBig">
                                    <p>{userLang.get("home.top.slogan")}</p>
                                </div>
                                <div className="top-big-link wow fadeInUp">
                                    <p><Link className="btn btn-primary btn-link-1" to="/">{userLang.get("home.top.feedback")}</Link></p>
                                    <p><Link className="btn btn-secondary btn-link-2" to="/login">{userLang.get("home.top.teacher")}</Link></p>
                                    <p><Link className="btn btn-secondary btn-link-3" to="/register">{userLang.get("home.top.school")}</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>
            </section>
        )
    }
    componentDidMount() {
        window.$('.top-content').backstretch('assets/img/background.webp')
    }
}