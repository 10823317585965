import React, { Component } from 'react';

export default class NavbarItem extends Component {
    state = { }
    render() {
        return (
            <li className="navItem">
                <a className="nav-link scroll-link" href={this.props.href}>{this.props.name}</a>
            </li>
        )
    }
}