let lang = []
let english = []

export default class Language {
    constructor(language) {
        try {
            lang = require(`../translations/${language}.json`)
        } catch (e) {}
        if (language !== 'en') english = require('../translations/en.json')
    }
    get(key, map) {
        var message = lang[key] || english[key] || key
        if (map)
            Object.keys(map).forEach(
                (key) => (message = message.replace(`{${key}}`, map[key]))
            )
        return message
    }
}
